// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDKQRhaEzLtLiNcH4CuO4zFuDZc0-m_LP4',
  authDomain: 'laudo-freela.firebaseapp.com',
  projectId: 'laudo-freela',
  storageBucket: 'laudo-freela.appspot.com',
  messagingSenderId: '372483722163',
  appId: '1:372483722163:web:946eda2688e0e53b1dd9c6',
  measurementId: 'G-BZ6CMBHC0V',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
