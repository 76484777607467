import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Home from '../pages/home';
import Checkout from '../pages/checkout';
import Login from '../pages/login';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Register from '../pages/register';
import Menu from '../pages/menu';
import { AuthContext } from '../contexts/auth';
import { useContext, useEffect } from 'react';
import FormNotebook from '../pages/notebook';
import FormPc from '../pages/pc';

const PrivateRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.access_token) {
      navigate('/login');
    }
  }, []);

  return children;
};

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cadastro" element={<Register />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route
          path="/menu"
          element={
            <PrivateRoute>
              <Menu />
            </PrivateRoute>
          }
        />
        <Route
          path="/notebook"
          element={
            <PrivateRoute>
              <FormNotebook />
            </PrivateRoute>
          }
        />
        <Route
          path="/pc"
          element={
            <PrivateRoute>
              <FormPc />
            </PrivateRoute>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
