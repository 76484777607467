import { Flex, Text, Button } from '@chakra-ui/react';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';

export default function Menu() {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  return (
    <Flex
      width="100%"
      height="calc(100vh - 30px)"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={5}
      position="relative"
    >
      <Text>Bem vindo, {user?.email}</Text>
      <Button
        width="250px"
        backgroundColor="#ff6100"
        borderRadius="8px"
        color="white"
        onClick={() => navigate('/notebook')}
      >
        Formulário Notebook
      </Button>
      <Button
        backgroundColor="#ff6100"
        borderRadius="8px"
        color="white"
        width="250px"
        onClick={() => navigate('/pc')}
      >
        Formulário PC
      </Button>
      <Flex
        flexDirection="column"
        position="absolute"
        left="40px"
        bottom="40px"
      >
        <Text fontWeight="700">Contato:</Text>
        <Text>tinapratica0800@gmail.com</Text>
      </Flex>
    </Flex>
  );
}
