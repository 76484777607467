import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
} from '@chakra-ui/react';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth } from '../../services/firebaseConfig';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const [createUserWithEmailAndPassword] =
    useCreateUserWithEmailAndPassword(auth);

  const registerUser = (event) => {
    event.preventDefault();
    createUserWithEmailAndPassword(email, password)
      .then(() => {
        alert('Usuário criado com sucesso!');
        navigate('/login');
      })
      .catch(() => alert('Não foi possível criar o usuário'));
  };

  return (
    <Flex
      width="100%"
      height="calc(100vh - 30px)"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={5}
    >
      <Text fontSize="30px" fontWeight="700">
        TI GENIUS
      </Text>
      <form style={{ minWidth: '350px' }} onSubmit={registerUser}>
        <Flex flexDirection="column" gap={5} alignItems="center">
          {' '}
          <Flex width="100%" gap={5}>
            {' '}
            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input type="text" placeholder="Digite seu nome" />
            </FormControl>
            <FormControl>
              <FormLabel>Sobrenome</FormLabel>
              <Input type="text" placeholder="Digite seu sobrenome" />
            </FormControl>
          </Flex>
          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input
              type="email"
              placeholder="Digite seu email"
              required
              onChange={({ target }) => setEmail(target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Senha</FormLabel>
            <Input
              type="password"
              placeholder="Digite sua senha"
              required
              onChange={({ target }) => setPassword(target.value)}
            />
          </FormControl>
          <Button
            backgroundColor="#ff6100"
            borderRadius="8px"
            color="white"
            width="100%"
            type="submit"
          >
            Cadastrar
          </Button>
        </Flex>
      </form>
    </Flex>
  );
}
