import { Flex, Text } from '@chakra-ui/react';

export default function Footer() {
  return (
    <Flex
      width="100%"
      height="30px"
      backgroundColor="#ff6100"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize="18px" fontWeight="700" color="white">
        TI GENIUS
      </Text>
    </Flex>
  );
}
