import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/auth';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  const login = () => {
    //https://tipratica.webtech-entrega-test.com.br/
    //http://
    fetch('https://accessmanager.tigeniuslab.com.br/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password
      })
    })
    .then(response => response.json())
    .then(data => {
      setUser(data);
      navigate('/menu');
    })
    .catch((error) => {
      console.error('Erro:', error);
    });
    /*signInWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        
      })
      .catch((error) => console.log('error', error));*/
  };

  return (
    <Flex
      width="100%"
      height="calc(100vh - 30px)"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={5}
    >
      <Text fontSize="30px" fontWeight="700">
        TI GENIUS
      </Text>
      <form style={{ minWidth: '350px' }}>
        <Flex flexDirection="column" gap={5} alignItems="center">
          {' '}
          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input
              type="email"
              placeholder="Digite seu email"
              onChange={({ target }) => setEmail(target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Senha</FormLabel>
            <Input
              type="password"
              placeholder="Digite sua senha"
              onChange={({ target }) => setPassword(target.value)}
            />
          </FormControl>
          <Button
            width="100%"
            backgroundColor="#ff6100"
            borderRadius="8px"
            color="white"
            onClick={login}
          >
            Entrar
          </Button>
        </Flex>
      </form>
    </Flex>
  );
}
