import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  useMediaQuery,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import html2pdf from 'html2pdf.js';
import logo from '../../assets/imagemPdf.jpg';

const STORAGE_OPTIONS = ['HD', 'M.2', 'NVME', 'M2 SATA', 'MSATA', 'SSD'];
const MEMORY_OPTIONS = ['DR2', 'DDR3', 'DDR4', 'DDR5'];
const MEMORY_SIZE_OPTIONS = ['2GB', '4GB', '8GB', '16GB', '32GB'];
const MEMORY_FREQUENCY_OPTIONS = ['1333MHZ', '1600MHZ', '2133MHZ', '2400MHZ'];
const BATTERY_OPTIONS = ['SEGURA CARGA', ' NÃO SEGURA CARGA'];
const BATTERY_BIOS_OPTIONS = ['RESETOU', ' NÃO RESETOU'];
const STATUS_OPTIONS = ['INICIANDO', 'CORROMPIDO'];
const STATUS_TYPE_OPTIONS = [
  'ESTUDANTE',
  'ENGENHEIRO',
  'ADVOGADO',
  'PROFESSOR',
];
const FONT_OPTIONS = ['ORIGINAL', 'PARALELA', 'GENERICA'];

const INITIAL_VALUES_REPORT_DATA = {
  technician: '',
  serviceOrder: '',
  client: '',
  description: '',
  storageOne: {
    type: '',
    size: '',
    health: '',
    backup: 0,
  },
  storageTwo: {
    type: '',
    size: '',
    health: '',
    backup: 0,
  },
  storageTree: {
    type: '',
    size: '',
    health: '',
    backup: 0,
  },
  memoryOne: {
    type: '',
    size: '',
    health: '',
    frequency: '',
  },
  memoryTwo: {
    type: '',
    size: '',
    health: '',
    frequency: '',
  },
  memoryTree: {
    type: '',
    size: '',
    health: '',
    frequency: '',
  },
  screen: '',
  keyboard: '',
  touchpad: '',
  sound: '',
  battery: '',
  batteryBios: '',
  breakdowns: '',
  operationalSystem: '',
  statusOperationalSystem: '',
  versionOperationalSystem: '',
  perfilOperationalSystem: '',
  complaint: '',
  fontHealth: '',
  sourceAuthenticity: '',
  cloudProgram: '',
  budget: '',
  feedback: '',
};

const TemplatePdf = ({ reportData, contentRef }) => {
  return (
    <div ref={contentRef}>
      <Box paddingBottom="40px">
        <Flex>
          <Text>Ordem de Serviço:</Text>
          <Text fontWeight="700" marginLeft="10px">
            {reportData.serviceOrder}
          </Text>
        </Flex>
        <Flex mb={4}>
          <Text>Cliente:</Text>
          <Text fontWeight="700" marginLeft="10px">
            {reportData.client}
          </Text>
        </Flex>

        <Flex mb={4} gap={10}>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Técnico:</Text>
            <Text>{reportData.technician}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Ordem de Serviço:</Text>
            <Text>{reportData.serviceOrder}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Cliente:</Text>
            <Text>{reportData.client}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Descrição:</Text>
            <Text>{reportData.description}</Text>
          </Flex>
        </Flex>
        <Flex mb={4} gap={10}>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Tipo de Armazenamento 1:</Text>
            <Text>{reportData.storageOne.type}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Tamanho de Armazenamento 1:</Text>
            <Text>{reportData.storageOne.size}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Saúde de Armazenamento 1:</Text>
            <Text>{reportData.storageOne.health}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Backup de Armazenamento 1:</Text>
            <Text>{reportData.storageOne.backup}GB</Text>
          </Flex>
        </Flex>
        {reportData.storageTwo.type.length > 0 && (
          <Flex mb={4} gap={10}>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Tipo de Armazenamento 2:</Text>
              <Text>{reportData.storageTwo.type}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Tamanho de Armazenamento 2:</Text>
              <Text>{reportData.storageTwo.size}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Saúde de Armazenamento 2:</Text>
              <Text>{reportData.storageTwo.health}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Backup de Armazenamento 2:</Text>
              <Text>{reportData.storageTwo.backup}GB</Text>
            </Flex>
          </Flex>
        )}
        {reportData.storageTree.type.length > 0 && (
          <Flex mb={4} gap={10}>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Tipo de Armazenamento 3:</Text>
              <Text>{reportData.storageTree.type}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Tamanho de Armazenamento 3:</Text>
              <Text>{reportData.storageTree.size}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Saúde de Armazenamento 3:</Text>
              <Text>{reportData.storageTree.health}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Backup de Armazenamento 3:</Text>
              <Text>{reportData.storageTree.backup}GB</Text>
            </Flex>
          </Flex>
        )}

        <Flex mb={4} gap={10}>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Tipo de Memória 1:</Text>
            <Text>{reportData.memoryOne.type}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Tamanho de Memória 1:</Text>
            <Text>{reportData.memoryOne.size}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Saúde de Memória 1:</Text>
            <Text>{reportData.memoryOne.health}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Frequência de Memória 1:</Text>
            <Text>{reportData.memoryOne.frequency}</Text>
          </Flex>
        </Flex>
        {reportData.memoryTwo.type.length > 0 && (
          <Flex mb={4} gap={10}>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Tipo de Memória 2:</Text>
              <Text>{reportData.memoryTwo.type}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Tamanho de Memória 2:</Text>
              <Text>{reportData.memoryTwo.size}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Saúde de Memória 2:</Text>
              <Text>{reportData.memoryTwo.health}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Frequência de Memória 2:</Text>
              <Text>{reportData.memoryTwo.frequency}</Text>
            </Flex>
          </Flex>
        )}

        {reportData.memoryTree.type.length > 0 && (
          <Flex mb={4} gap={10}>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Tipo de Memória 3:</Text>
              <Text>{reportData.memoryTree.type}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Tamanho de Memória 3:</Text>
              <Text>{reportData.memoryTree.size}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Saúde de Memória 3:</Text>
              <Text>{reportData.memoryTree.health}</Text>
            </Flex>
            <Flex w="25%" flexDirection="column">
              <Text fontWeight="bold">Frequência de Memória 3:</Text>
              <Text>{reportData.memoryTree.frequency}</Text>
            </Flex>
          </Flex>
        )}

        <Flex mb={4} gap={10}>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Tela:</Text>
            <Text>{reportData.screen}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Teclado:</Text>
            <Text>{reportData.keyboard}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Touchpad:</Text>
            <Text>{reportData.touchpad}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Som:</Text>
            <Text>{reportData.sound}</Text>
          </Flex>
        </Flex>
        <Flex mb={4} gap={10}>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Bateria:</Text>
            <Text>{reportData.battery}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Bateria BIOS:</Text>
            <Text>{reportData.batteryBios}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Falhas:</Text>
            <Text>{reportData.breakdowns}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Sistema Operacional:</Text>
            <Text>{reportData.operationalSystem}</Text>
          </Flex>
        </Flex>
        <Flex mb={4} gap={10} className="page-break">
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Status do Sistema Operacional:</Text>
            <Text>{reportData.statusOperationalSystem}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Versão do Sistema Operacional:</Text>
            <Text>{reportData.versionOperationalSystem}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Perfil do Sistema Operacional:</Text>
            <Text>{reportData.perfilOperationalSystem}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Reclamação:</Text>
            <Text>{reportData.complaint}</Text>
          </Flex>
        </Flex>
        <Flex mb={4} gap={10}>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Fonte de Saúde:</Text>
            <Text>{reportData.fontHealth}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Autenticidade da Fonte:</Text>
            <Text>{reportData.sourceAuthenticity}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column">
            <Text fontWeight="bold">Programa em Nuvem:</Text>
            <Text>{reportData.cloudProgram}</Text>
          </Flex>
          <Flex w="25%" flexDirection="column"></Flex>
        </Flex>
        <Flex width="100%" flexDirection="column">
          <Text fontWeight="bold">Orçamento:</Text>
          <Text>{reportData.budget}</Text>
        </Flex>
      </Box>
    </div>
  );
};

export default function FormNotebook() {
  const [showMoreMemories, setShowMoreMemories] = useState(false);
  const [showMoreStorage, setShowMoreStorage] = useState(false);
  const [reportData, setReportData] = useState(INITIAL_VALUES_REPORT_DATA);

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const contentRef = useRef(null);

  const options = {
    filename: `${reportData.serviceOrder}_${reportData.client}.pdf`,
    image: { type: 'jpg', quality: 0.98 },
    margin: [0.2, 0.5],
    html2canvas: { scale: 1 },
    jsPDF: {
      unit: 'in',
      format: 'A4',
      orientation: 'landscape',
    },
  };

  const generatePdf = (event) => {
    event.preventDefault();
    const content = contentRef.current;

    html2pdf().set(options).from(content).save();
  };

  return (
    <Flex
      flexDirection="column"
      width="100%"
      padding="80px 15px"
      gap={10}
      alignItems="center"
    >
      <img
        src={logo}
        style={{ width: '200px', height: '200px', borderRadius: '50%' }}
        alt="logo"
      />
      <Text fontWeight="700" fontSize="24px" maxW="400px" textAlign="center">
        DIGITE SEU LAUDO TÉCNICO E GERE UM ORÇAMENTO PARA O SEU CLIENTE
      </Text>
      <form
        onSubmit={generatePdf}
        style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
      >
        <Flex
          width="100%"
          gap={isLargerThan800 ? (isLargerThan800 ? 20 : 5) : 5}
        >
          <FormControl>
            <FormLabel>Técnico</FormLabel>
            <Input
              type="text"
              required
              value={reportData.technician}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  technician: target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Ordem de serviço</FormLabel>
            <Input
              type="text"
              required
              value={reportData.serviceOrder}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  serviceOrder: target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Cliente</FormLabel>
            <Input
              type="text"
              required
              value={reportData.client}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  client: target.value,
                }))
              }
            />
          </FormControl>
        </Flex>
        <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
          <FormControl>
            <FormLabel>
              DESCRIÇÃO DO PROBLEMA, DIAGNÓSTICO E SOLUÇÃO PARA O DEFEITO
              RECLAMADO PELO CLIENTE
            </FormLabel>
            <Textarea
              resize="none"
              required
              value={reportData.description}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  description: target.value,
                }))
              }
            ></Textarea>
          </FormControl>
        </Flex>
        <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
          <FormControl>
            <FormLabel>Armazenamento</FormLabel>
            <Select
              placeholder="Selecione um tipo de armazenamento"
              value={reportData.storageOne.type}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  storageOne: {
                    ...prevState.storageOne,
                    type: target.value,
                  },
                }))
              }
            >
              {STORAGE_OPTIONS.map((storageType) => (
                <option value={storageType} key={storageType}>
                  {storageType}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Tamanho do armazenamento</FormLabel>
            <Input
              type="text"
              value={reportData.storageOne.size}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  storageOne: {
                    ...prevState.storageOne,
                    size: target.value,
                  },
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Saúde do armazenamento</FormLabel>
            <Input
              type="text"
              value={reportData.storageOne.health}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  storageOne: {
                    ...prevState.storageOne,
                    health: target.value,
                  },
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Tamanho aproximado do backup (GB)</FormLabel>
            <Input
              type="number"
              min={0}
              value={reportData.storageOne.backup}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  storageOne: {
                    ...prevState.storageOne,
                    backup: target.value,
                  },
                }))
              }
            />
          </FormControl>
        </Flex>
        {showMoreStorage && (
          <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
            <FormControl>
              <FormLabel>Armazenamento</FormLabel>
              <Select
                placeholder="Selecione um tipo de armazenamento"
                value={reportData.storageTwo.type}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    storageTwo: {
                      ...prevState.storageTwo,
                      type: target.value,
                    },
                  }))
                }
              >
                {STORAGE_OPTIONS.map((storageType) => (
                  <option value={storageType} key={storageType}>
                    {storageType}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Tamanho do armazenamento</FormLabel>
              <Input
                type="text"
                value={reportData.storageTwo.size}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    storageTwo: {
                      ...prevState.storageTwo,
                      size: target.value,
                    },
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Saúde do armazenamento</FormLabel>
              <Input
                type="text"
                value={reportData.storageTwo.health}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    storageTwo: {
                      ...prevState.storageTwo,
                      health: target.value,
                    },
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Tamanho aproximado do backup (GB)</FormLabel>
              <Input
                type="number"
                min={0}
                value={reportData.storageTwo.backup}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    storageTwo: {
                      ...prevState.storageTwo,
                      backup: target.value,
                    },
                  }))
                }
              />
            </FormControl>
          </Flex>
        )}
        {showMoreStorage && (
          <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
            <FormControl>
              <FormLabel>Armazenamento</FormLabel>
              <Select
                placeholder="Selecione um tipo de armazenamento"
                value={reportData.storageTree.type}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    storageTree: {
                      ...prevState.storageTree,
                      type: target.value,
                    },
                  }))
                }
              >
                {STORAGE_OPTIONS.map((storageType) => (
                  <option value={storageType} key={storageType}>
                    {storageType}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Tamanho do armazenamento</FormLabel>
              <Input
                type="text"
                value={reportData.storageTree.size}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    storageTree: {
                      ...prevState.storageTree,
                      size: target.value,
                    },
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Saúde do armazenamento</FormLabel>
              <Input
                type="text"
                value={reportData.storageTree.health}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    storageTree: {
                      ...prevState.storageTree,
                      health: target.value,
                    },
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Tamanho aproximado do backup (GB)</FormLabel>
              <Input
                type="number"
                min={0}
                value={reportData.storageTree.backup}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    storageTree: {
                      ...prevState.storageTree,
                      backup: target.value,
                    },
                  }))
                }
              />
            </FormControl>
          </Flex>
        )}
        <Flex width="100%" justifyContent="center">
          <Button
            onClick={() => setShowMoreStorage(!showMoreStorage)}
            variant="solid"
            backgroundColor="#ff6100"
            color="white"
          >
            Adicionar mais
          </Button>
        </Flex>
        <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
          <FormControl>
            <FormLabel>Memória</FormLabel>
            <Select
              placeholder="Selecione um tipo de memória"
              required
              value={reportData.memoryOne.type}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  memoryOne: {
                    ...prevState.memoryOne,
                    type: target.value,
                  },
                }))
              }
            >
              {MEMORY_OPTIONS.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Tamanho da memória</FormLabel>
            <Select
              placeholder="Selecione o tamanho de memória"
              value={reportData.memoryOne.size}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  memoryOne: {
                    ...prevState.memoryOne,
                    size: target.value,
                  },
                }))
              }
            >
              {MEMORY_SIZE_OPTIONS.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Saúde da memória</FormLabel>
            <Input
              type="text"
              value={reportData.memoryOne.health}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  memoryOne: {
                    ...prevState.memoryOne,
                    health: target.value,
                  },
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Frequência da memória</FormLabel>
            <Select
              placeholder="Selecione a frequência da memória"
              required
              value={reportData.memoryOne.frequency}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  memoryOne: {
                    ...prevState.memoryOne,
                    frequency: target.value,
                  },
                }))
              }
            >
              {MEMORY_FREQUENCY_OPTIONS.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
        {showMoreMemories && (
          <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
            <FormControl>
              <FormLabel>Memória</FormLabel>
              <Select
                placeholder="Selecione um tipo de memória"
                value={reportData.memoryTwo.type}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    memoryTwo: {
                      ...prevState.memoryTwo,
                      type: target.value,
                    },
                  }))
                }
              >
                {MEMORY_OPTIONS.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Tamanho da memória</FormLabel>
              <Select
                placeholder="Selecione o tamanho de memória"
                value={reportData.memoryTwo.size}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    memoryTwo: {
                      ...prevState.memoryTwo,
                      size: target.value,
                    },
                  }))
                }
              >
                {MEMORY_SIZE_OPTIONS.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Saúde da memória</FormLabel>
              <Input
                type="text"
                value={reportData.memoryTwo.health}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    memoryTwo: {
                      ...prevState.memoryTwo,
                      health: target.value,
                    },
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Frequência da memória</FormLabel>
              <Select
                placeholder="Selecione a frequência da memória"
                value={reportData.memoryTwo.frequency}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    memoryTwo: {
                      ...prevState.memoryTwo,
                      frequency: target.value,
                    },
                  }))
                }
              >
                {MEMORY_FREQUENCY_OPTIONS.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
        )}
        {showMoreMemories && (
          <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
            <FormControl>
              <FormLabel>Memória</FormLabel>
              <Select
                placeholder="Selecione um tipo de memória"
                value={reportData.memoryTree.type}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    memoryTree: {
                      ...prevState.memoryTree,
                      type: target.value,
                    },
                  }))
                }
              >
                {MEMORY_OPTIONS.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Tamanho da memória</FormLabel>
              <Select
                placeholder="Selecione o tamanho de memória"
                value={reportData.memoryTree.size}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    memoryTree: {
                      ...prevState.memoryTree,
                      size: target.value,
                    },
                  }))
                }
              >
                {MEMORY_SIZE_OPTIONS.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Saúde da memória</FormLabel>
              <Input
                type="text"
                value={reportData.memoryTree.health}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    memoryTree: {
                      ...prevState.memoryTree,
                      health: target.value,
                    },
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Frequência da memória</FormLabel>
              <Select
                placeholder="Selecione a frequência da memória"
                value={reportData.memoryTree.frequency}
                onChange={({ target }) =>
                  setReportData((prevState) => ({
                    ...prevState,
                    memoryTree: {
                      ...prevState.memoryTree,
                      frequency: target.value,
                    },
                  }))
                }
              >
                {MEMORY_FREQUENCY_OPTIONS.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
        )}
        <Flex width="100%" justifyContent="center">
          <Button
            onClick={() => setShowMoreMemories(!showMoreMemories)}
            variant="solid"
            backgroundColor="#ff6100"
            color="white"
          >
            Adicionar mais
          </Button>
        </Flex>
        <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
          <FormControl>
            <FormLabel>Tela</FormLabel>
            <Input
              type="text"
              value={reportData.screen}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  screen: target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Teclado</FormLabel>
            <Input
              type="text"
              value={reportData.keyboard}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  keyboard: target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Touchpad</FormLabel>
            <Input
              type="text"
              value={reportData.touchpad}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  touchpad: target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Aúdio</FormLabel>
            <Input
              type="text"
              value={reportData.sound}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  sound: target.value,
                }))
              }
            />
          </FormControl>
        </Flex>
        <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
          <FormControl>
            <FormLabel>Bateria</FormLabel>
            <Select
              placeholder="Selecione uma opção"
              value={reportData.battery}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  battery: target.value,
                }))
              }
            >
              {BATTERY_OPTIONS.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Bateria de BIOS</FormLabel>
            <Select
              placeholder="Selecione uma opção"
              value={reportData.batteryBios}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  batteryBios: target.value,
                }))
              }
            >
              {BATTERY_BIOS_OPTIONS.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Avarias</FormLabel>
            <Input
              type="text"
              value={reportData.breakdowns}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  breakdowns: target.value,
                }))
              }
            />
          </FormControl>
          <FormControl></FormControl>
        </Flex>
        <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
          <FormControl>
            <FormLabel>Sistema operacional</FormLabel>
            <Input
              type="text"
              value={reportData.operationalSystem}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  operationalSystem: target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Versão do sistema operacional</FormLabel>
            <Input
              type="text"
              value={reportData.versionOperationalSystem}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  versionOperationalSystem: target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Status do sistema operacional</FormLabel>
            <Select
              placeholder="Selecione uma opção"
              value={reportData.statusOperationalSystem}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  statusOperationalSystem: target.value,
                }))
              }
            >
              {STATUS_OPTIONS.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Perfil do sistema operacional</FormLabel>
            <Select
              placeholder="Selecione uma opção"
              value={reportData.perfilOperationalSystem}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  perfilOperationalSystem: target.value,
                }))
              }
            >
              {STATUS_TYPE_OPTIONS.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
        <Flex width="100%" gap={isLargerThan800 ? 20 : 5}>
          <FormControl>
            <FormLabel>Programa de nuvem</FormLabel>
            <Input
              type="text"
              value={reportData.cloudProgram}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  cloudProgram: target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Autenticidade da fonte</FormLabel>
            <Select
              placeholder="Selecione uma opção"
              value={reportData.sourceAuthenticity}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  sourceAuthenticity: target.value,
                }))
              }
            >
              {FONT_OPTIONS.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Saúde da fonte</FormLabel>
            <Input
              type="text"
              value={reportData.fontHealth}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  fontHealth: target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Reclamação do cliente</FormLabel>
            <Input
              type="text"
              value={reportData.complaint}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  complaint: target.value,
                }))
              }
            />
          </FormControl>
        </Flex>
        <Flex width="100%">
          <FormControl>
            <FormLabel>Orçamento</FormLabel>
            <Textarea
              resize="none"
              minH="200px"
              value={reportData.budget}
              onChange={({ target }) =>
                setReportData((prevState) => ({
                  ...prevState,
                  budget: target.value,
                }))
              }
            ></Textarea>
          </FormControl>
        </Flex>
        <Button
          variant="solid"
          backgroundColor="#ff6100"
          color="white"
          type="submit"
        >
          SALVAR
        </Button>
      </form>
      {
        <Flex display="none">
          <TemplatePdf contentRef={contentRef} reportData={reportData} />
        </Flex>
      }
    </Flex>
  );
}
