import { Button, Flex, Grid, Text, useMediaQuery } from '@chakra-ui/react';
import homeImage from '../../assets/imageHome.jpg';
import imageMotivos1 from '../../assets/motivos1.jpeg';
import imageMotivos2 from '../../assets/motivos2.jpg';
import imageMotivos3 from '../../assets/motivos3.jpeg';
import imageMotivos4 from '../../assets/motivos4.jpeg';
import imageMotivos5 from '../../assets/motivos5.jpg';
import { useNavigate } from 'react-router-dom';
import React from 'react';
//import { AuthContext } from '../../contexts/auth';
//import { useContext } from 'react';

const Card = ({
  id = '',
  title = '',
  subtitle = '',
  price = '',
  actionText = '',
  resources = '',
  isActive = true,
}) => {
  const navigate = useNavigate();
  return (
    <Flex
      flexDirection="column"
      padding="10px"
      alignItems="center"
      gap={2}
      border="1px solid #ff6100"
      borderRadius="8px"
    >
      <Text color="#ff6100" fontSize="27px">
        {title}
      </Text>
      {isActive ? (
        <>
          <Text color="#ff6100">{subtitle}</Text>
          <Text fontSize="40px" fontWeight="700" color="#ff6100">
            {price}
          </Text>
          <Button
            backgroundColor="transparent"
            border="1px solid #ff6100"
            borderRadius="8px"
            color="#ff6100"
            onClick={()=> {
              localStorage.setItem('plano_selecionado', id);
              localStorage.setItem('titulo', title);
              localStorage.setItem('preco', price);
              navigate('/checkout')
            }}
          >
            {actionText}
          </Button>
          <Text color="#ff6100">Principais recursos</Text>
          {resources.map((resource, index) => (
            <Text color="#ff6100" key={index}>
              {resource}
            </Text>
          ))}
        </>
      ) : (
        <Text fontSize="40px" fontWeight="700" color="#ff6100">
          EM BREVE
        </Text>
      )}
    </Flex>
  );
};

export default function Home() {
  const [plans, setPlans] = React.useState([]);

  React.useEffect(()=>{
    
    function buscarPlanos() {
      fetch('https://pagamentosapi.tigeniuslab.com.br/plans', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(async (data) => {
        const planos = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const priceInReal = (element.amount_value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  
          const plano = {
              id: element.reference_id,
              title: element.name,
              subtitle: element.description,
              price: priceInReal,
              actionText: 'ESCOLHER PLANO',
              resources: ['1 Usuário', 'Downloads Ilimitados', 'Laudo de notebook e pc'],
          };
  
          planos.push(plano);
        }

        setPlans(planos);
      })
      .catch((error) => {
        console.error('Erro:', error);
      });
    }

    buscarPlanos();
  }, []);
  
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  return (
    <Flex flexDirection="column">
      <Flex width="100%" justifyContent="center" backgroundColor="#ffdac5">
        <Flex w={isLargerThan800 ? '70%' : '90%'} py="50px" marginTop="60px">
          <Flex width="100%" flexDirection="column">
            {' '}
            <Flex width="100%">
              {' '}
              <Flex
                flex={1}
                flexDirection="column"
                gap={5}
                justifyContent="center"
              >
                <Text
                  maxW="600px"
                  fontSize="40px"
                  fontWeight="700"
                  color="#ff6100"
                >
                  A MELHOR FERRAMENTA DE LAUDO PARA MANUTENÇÃO DE COMPUTADORES
                </Text>
                <Text maxW="500px" color="#ff6100" fontWeight="700">
                  Crie de forma ágil laudos de NOTEBOOK e PC profissionais que
                  gera confiança para o cliente e rapidez na hora de realizar o
                  serviço, impactando positivamente no seu bolso.
                </Text>
                <Button
                  maxW="250px"
                  backgroundColor="#ff6100"
                  color="white"
                  as="a"
                  href="#plans"
                >
                  CONHECER PLANOS
                </Button>
              </Flex>
              {isLargerThan800 && (
                <Flex flex={1}>
                  <img
                    src={homeImage}
                    alt="imagem-principal"
                    style={{
                      width: '500px',
                      height: '500px',
                      borderRadius: '50%',
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex width="100%" justifyContent="center" backgroundColor={'#ffdac5'}>
        {' '}
        <Flex
          w={isLargerThan800 ? '70%' : '90%'}
          py="60px"
          flexDirection="column"
          alignItems="center"
          gap={20}
        >
          {' '}
          <Text fontSize="34px" fontWeight="700">
            POR QUE PRECISO DE UMA FERRAMENTA DE LAUDO?
          </Text>
          <Flex width="100%" gap={10}>
            <Flex flex={1} flexDirection="column" justifyContent="center">
              <Text fontSize="40px" fontWeight="700" color="#ff6100">
                Prova de Serviço:
              </Text>
              <Text fontSize="22px">
                Um laudo pode servir como prova de que o serviço executado foi
                realizado conforme solicitado pelo cliente.
              </Text>
            </Flex>
            {isLargerThan800 && (
              <Flex flex={1}>
                <img
                  src={imageMotivos1}
                  alt="imagem-1"
                  style={{ borderRadius: '8px' }}
                />
              </Flex>
            )}
          </Flex>
          <Flex width="100%" gap={10}>
            {isLargerThan800 && (
              <Flex flex={1}>
                <img
                  src={imageMotivos2}
                  alt="imagem-1"
                  style={{ borderRadius: '8px' }}
                />
              </Flex>
            )}
            <Flex flex={1} flexDirection="column" justifyContent="center">
              <Text fontSize="40px" fontWeight="700" color="#ff6100">
                Registro de Problemas e Soluções:
              </Text>
              <Text fontSize="22px">
                O Laudo pode documentar detalhadamente os problemas encontrados
                no sistema ou dispositivo, deixando claro para o cliente a
                solução aplicada antes ou depois da autorização.
              </Text>
            </Flex>
          </Flex>
          <Flex width="100%" gap={10}>
            <Flex flex={1} flexDirection="column" justifyContent="center">
              <Text fontSize="40px" fontWeight="700" color="#ff6100">
                Comprovação de Falhas:
              </Text>
              <Text fontSize="22px">
                Em Situações que uma máquina ou sistema continua apresentando
                problemas após a manutenção, um laudo pode ajudar a comprovar
                que a falha não está relacionada ao serviço prestado pelo
                técnico
              </Text>
            </Flex>
            {isLargerThan800 && (
              <Flex flex={1}>
                <img
                  src={imageMotivos3}
                  alt="imagem-1"
                  style={{ borderRadius: '8px' }}
                />
              </Flex>
            )}
          </Flex>
          <Flex width="100%" gap={10}>
            {isLargerThan800 && (
              <Flex flex={1}>
                <img
                  src={imageMotivos4}
                  alt="imagem-1"
                  style={{ borderRadius: '8px' }}
                />
              </Flex>
            )}
            <Flex flex={1} flexDirection="column" justifyContent="center">
              <Text fontSize="40px" fontWeight="700" color="#ff6100">
                Registro de Alterações:
              </Text>
              <Text fontSize="22px">
                Para assistências que precisam manter um registro detalhado de
                todas as alterações feitas em seus sistemas, um laudo pode ser
                uma parte essencial desse registro.
              </Text>
            </Flex>
          </Flex>
          <Flex width="100%" gap={10}>
            <Flex flex={1} flexDirection="column" justifyContent="center">
              <Text fontSize="40px" fontWeight="700" color="#ff6100">
                Transparência e Confiança:
              </Text>
              <Text fontSize="22px">
                Ao fornecer um laudo detalhado o técnico demonstra transparência
                e profissionalismo, construindo assim a confiança do cliente em
                seu trabalho e expertise.
              </Text>
            </Flex>
            {isLargerThan800 && (
              <Flex flex={1}>
                <img
                  src={imageMotivos5}
                  alt="imagem-1"
                  style={{ borderRadius: '8px' }}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex width="100%" justifyContent="center">
        <Flex
          w={isLargerThan800 ? '70%' : '90%'}
          py="30px"
          flexDirection="column"
          alignItems="center"
          gap={5}
          id="plans"
        >
          <Text fontSize="30px" color="#ff6100" fontWeight="700">
            Escolha seu plano de assinatura
          </Text>
          {plans.map((props, index) => (
            <Card {...props} key={index} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
