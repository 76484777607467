import { Flex, useMediaQuery, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { auth } from '../../services/firebaseConfig';

export default function Header() {
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);

  const logout = () => {
    signOut(auth).then(() => {
      setUser({});
      navigate('/');
    });
  };

  return (
    <Flex
      width="100%"
      py="10px"
      backgroundColor="#ff6100"
      justifyContent="center"
      position="fixed"
      zIndex={999}
    >
      <Flex w={isLargerThan800 ? '70%' : '90%'} justifyContent="space-between">
        <Text
          fontSize="24px"
          fontWeight="700"
          onClick={() => navigate('/')}
          color="white"
          cursor="pointer"
        >
          TI GENIUS
        </Text>
        <Flex justifyContent="flex-end" gap={2}>
          {user.accessToken ? (
            <>
              {' '}
              <Button
                backgroundColor="white"
                borderRadius="8px"
                color="#ff6100"
                onClick={() => navigate('/menu')}
              >
                Menu
              </Button>{' '}
              <Button
                backgroundColor="white"
                borderRadius="8px"
                color="#ff6100"
                onClick={logout}
              >
                Sair
              </Button>
            </>
          ) : (
            <>
              <Button
                backgroundColor="white"
                borderRadius="8px"
                color="#ff6100"
                onClick={() => navigate('/login')}
              >
                Entrar
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
