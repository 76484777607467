import { useEffect, useState } from 'react';
import {
  Button, Flex, Box, Text, Stepper, Step, StepIndicator, StepStatus, StepIcon, StepNumber,
  StepTitle, StepDescription, StepSeparator, Input, useMediaQuery, Alert, AlertIcon,
  AlertTitle, AlertDescription, Heading, useSteps, Radio, RadioGroup, Stack, Spinner, useDisclosure,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const steps = [
  { title: 'Informações do Cliente', description: 'Preencha seus dados pessoais' },
  { title: 'Informações de Pagamento', description: 'Detalhes de pagamento' },
  { title: 'Confirmação', description: 'Confirme seu pedido' }
]

export default function Checkout() {
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const { activeStep, setActiveStep, getStatus, goToNext, goToPrevious } = useSteps({
    count: steps.length,
  });
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  // Nome do cliente
  const [clienteName, setClienteName] = useState('');
  const [errorClienteName, setErrorClienteName] = useState(false);
  const handleChangeClienteName = (event) => {
    setClienteName(event.target.value);
    setErrorClienteName(false);
  };
  // Email do cliente
  const [clienteEmail, setClienteEmail] = useState('');
  const [errorClienteEmail, setErrorClienteEmail] = useState(false);
  const handleChangeClienteEmail = (event) => {
    setClienteEmail(event.target.value);
    setErrorClienteEmail(false);
  };
  // CPF do cliente
  const [clienteCpf, setClienteCpf] = useState('');
  const [errorClienteCpf, setErrorClienteCpf] = useState(false);
  const handleChangeClienteCpf = (event) => {
    setClienteCpf(event.target.value);
    setErrorClienteCpf(false);
  };
  // DDD do cliente
  const [clienteDdd, setClienteDdd] = useState('');
  const [errorClienteDdd, setErrorClienteDdd] = useState(false);
  const handleChangeClienteDdd = (event) => {
    setClienteDdd(event.target.value);
    setErrorClienteDdd(false);
  };
  // Número de telefone do cliente
  const [clientePhone, setClientePhone] = useState('');
  const [errorClientePhone, setErrorClientePhone] = useState(false);
  const handleChangeClientePhone = (event) => {
    setClientePhone(event.target.value);
    setErrorClientePhone(false);
  };
  // Número do cartão
  const [numeroCartao, setNumeroCartao] = useState('');
  const [errorNumeroCartao, setErrorNumeroCartao] = useState(false);
  const handleChangeNumeroCartao = (event) => {
    setNumeroCartao(event.target.value);
    setErrorNumeroCartao(false);
  };
  // CVV Cartão
  const [cvvCartao, setCvvCartao] = useState('');
  const [errorCvvCartao, setErrorCvvCartao] = useState(false);
  const handleChangeCvvCartao = (event) => {
    setCvvCartao(event.target.value);
    setErrorCvvCartao(false);
  };
  // Validade Cartão
  const [validadeCartao, setValidadeCartao] = useState('');
  const [errorValidadeCartao, setErrorValidadeCartao] = useState(false);
  const handleChangeValidadeCartao = (event) => {
    let value = event.target.value;
    // Remove caracteres que não sejam dígitos e a barra
    value = value.replace(/[^\d/]/g, '');
  
    // Adiciona uma barra automaticamente após MM
    if (value.length === 2 && validadeCartao.length <= 2) {
      value += '/';
    }
  
    // Limita a entrada para 7 caracteres (MM/AAAA)
    if (value.length > 7) {
      value = value.substring(0, 7);
    }
  
    setValidadeCartao(value);
    setErrorValidadeCartao(false);
  };
  // Nome do titular do cartão
  const [titularCartao, setTitularCartao] = useState('');
  const [errorTitularCartao, setErrorTitularCartao] = useState(false);
  const handleChangeTitularCartao = (event) => {
    let value = event.target.value;
    setTitularCartao(value);
    setErrorTitularCartao(false);
  };
  // CPF do titular
  const [cpfTitular, setCpfTitular] = useState('');
  const [errorCpfTitular, setErrorCpfTitular] = useState(false);
  const handleChangeCpfTitular = (event) => {
    let value = event.target.value;
    setCpfTitular(value);
    setErrorCpfTitular(false);
  };
  // Iniciar Pagameto
  const [iniciarPagamento, setIniciarPagamento] = useState(false);
  // Falha no Pagamento
  const [existeFalha, setExisteFalha] = useState(false);
  // Mensagem Indicando motivo da falha
  const [motivoFalha, setMotivoFalha] = useState({
    title: '',
    message: ''
  });
  // Pagamento Concluido com sucesso
  const [pagamentoConcluido, setPagamentoConcluido] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Dados do plano selecionado
  const [planTitle, setPlanTitle] = useState('');
  const [planValue, setPlanValue] = useState('');

  useEffect(() => {
    const titulo = localStorage.getItem('titulo');
    const value = localStorage.getItem('preco');
    setPlanTitle(titulo);
    setPlanValue(value);
  }, [])

  function handleContinue() {
    if (clienteName === "") {
      setErrorClienteName(true);
    } else if(clienteEmail === "") {
      setErrorClienteEmail(true);
    } else if(clienteCpf === "") {
      setErrorClienteCpf(true);
    } else if(clienteDdd === "") {
      setErrorClienteDdd(true);
    } else if(clientePhone === "") {
      setErrorClientePhone(true);
    } else {
      goToNext();
    }
  }

  function handleFinancialStage() {
    if(numeroCartao === "") {
      setErrorNumeroCartao(true);
    } else if(cvvCartao === "") {
      setErrorCvvCartao(true);
    } else if(validadeCartao === "") {
      setErrorValidadeCartao(true);
    } else {
      goToNext();
    }
  }

  function handleFinancialStageOtherTitular() {
    if(titularCartao === "") {
      setErrorTitularCartao(true);
    } else if (cpfTitular === "") {
      setErrorCpfTitular(true);
    } else if(numeroCartao === "") {
      setErrorNumeroCartao(true);
    } else if(cvvCartao === "") {
      setErrorCvvCartao(true);
    } else if(validadeCartao === "") {
      setErrorValidadeCartao(true);
    } else {
      goToNext();
    }
  }

  function confirmarAssinatura() {
    setIniciarPagamento(true);
    // MONTAR OBJETO DE ENVIO PARA O GATEWAY DE PAGAMENTO
    
    const plano_selecionado = localStorage.getItem('plano_selecionado');
    
    if(plano_selecionado !== undefined && plano_selecionado !== null && plano_selecionado !== "")
    {
      let obj = {
        plan: {
          id: plano_selecionado
        },
        customer: {
          name: clienteName,
          email: clienteEmail,
          tax_id: clienteCpf,
          phones: [
            {
              area: clienteDdd,
              country: '55',
              number: clientePhone
            }
          ],
          billing_info: [
            {
              type: 'CREDIT_CARD',
              card: {
                number: numeroCartao,
                exp_year: validadeCartao.split('/')[1],
                exp_month: validadeCartao.split('/')[0],
                holder: {
                  name: titularCartao !== '' ? titularCartao : clienteName,
                  tax_id: cpfTitular !== '' ? cpfTitular : clienteCpf
                }
              }
            }
          ]
        },
        payment_method: [
          {
            type: 'CREDIT_CARD',
            card: {
              security_code: cvvCartao
            }
          }
        ]
      }

      //https://tipratica.webtech-entrega-test.com.br/recurring-payment
      //http://localhost:3001/recurring-payment
      fetch('https://pagamentosapi.tigeniuslab.com.br/recurring-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj)
      })
      .then(response => response.json())
      .then(data => {
        if(data.status === "ACTIVE") {
          setIniciarPagamento(false);
          setPagamentoConcluido(true);
          onOpen();
        } else {
          setIniciarPagamento(false);
          setExisteFalha(true);
          setMotivoFalha({
            title:'Falha no pagamento',
            message: 'Houve uma falha no pagamento, por favor verifique o motivo com a operadora do cartão, ou tente novamente.'
          });
        }
      })
      .catch((error) => {
        console.error('Erro:', error);

        // ToDo Incluir Mensagem de erro aqui
        // Por ser um erro na chamada devemos passar uma mensagem de erro no servidor
      });
    } else {
      // ToDo: Bolar mensagem de erro aqui
      // Por algum motivo a pessoa clicou e o plano não foi armazenado na variavel local, ou acessou diretamente o checkout.
    }
  }

  function handleBack() {
    goToPrevious();
  }

  return (
    <Flex flexDirection="column" alignItems="center" p={5} backgroundColor="#ffdac5">
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Seu Pagamento foi Confirmado</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Um Email com suas credencias de acesso foi encaminhado, por favor olhe sua caixa de span caso
              não esteja localizado na sua caixa de entrada.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='orange' mr={3} onClick={()=> navigate('/login')}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>   
      <Box width="90%" mt={150} mb={400} bg="#fff" p={5} borderRadius="lg" boxShadow="md">
        <Heading size="lg" mb={4} color='tomato'>Finalize seu Pedido</Heading>
        <Stepper size='lg' orientation={isLargerThan800 ? 'horizontal' : 'vertical'} colorScheme='red' index={activeStep}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box flexShrink='0'>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <Flex flexDirection="column" mb={4} mt={8} width={isLargerThan800 ? "70%" : "100%"}>
            <Text fontSize="xl" mb={4} color='tomato'>Informações do Comprador</Text>
            <Input placeholder="Digite Seu Nome" mb={2} value={clienteName} onChange={handleChangeClienteName} />
            {errorClienteName && (
              <Alert status='warning'>
                <AlertIcon />
                <AlertTitle>Campo Obrigatório</AlertTitle>
                <AlertDescription>É necessário preencher seu nome para continuar.</AlertDescription>
              </Alert>
            )}
            <Input placeholder="Digite seu endereço de E-mail" mb={2} value={clienteEmail} onChange={handleChangeClienteEmail} />
            {errorClienteEmail && (
              <Alert status='warning'>
                <AlertIcon />
                <AlertTitle>Campo Obrigatório</AlertTitle>
                <AlertDescription>É necessário preencher seu Email para continuar.</AlertDescription>
              </Alert>
            )}
            <Input placeholder="Digite seu CPF sem Adicionar pontos e traços" mb={2} value={clienteCpf} onChange={handleChangeClienteCpf} />
            {errorClienteCpf && (
              <Alert status='warning'>
                <AlertIcon />
                <AlertTitle>Campo Obrigatório</AlertTitle>
                <AlertDescription>É necessário preencher seu CPF para continuar.</AlertDescription>
              </Alert>
            )}
            <Flex justifyContent="space-between">
              <Input placeholder="DDD" maxLength={2} width="30%" mb={2} value={clienteDdd} onChange={handleChangeClienteDdd} />
              <Input placeholder="Telefone" type='tel' width="68%" mb={2} value={clientePhone} onChange={handleChangeClientePhone} />
            </Flex>
            {errorClienteDdd && (
              <Alert status='warning'>
                <AlertIcon />
                <AlertTitle>Campo Obrigatório</AlertTitle>
                <AlertDescription>É necessário preencher o DDD do seu número de telefone para continuar.</AlertDescription>
              </Alert>
            )}
            {errorClientePhone && (
              <Alert status='warning'>
                <AlertIcon />
                <AlertTitle>Campo Obrigatório</AlertTitle>
                <AlertDescription>É necessário preencher seu Número de telefone para continuar.</AlertDescription>
              </Alert>
            )}
            <Button colorScheme='orange' mt={4} onClick={handleContinue}>Continuar</Button>
          </Flex>
        )}

        {activeStep === 1 && (
          <Flex flexDirection="column" mb={4} mt={8} width={isLargerThan800 ? "70%" : "100%"}>
            <Text fontSize="xl" mb={4} color='tomato'>{steps[activeStep].description}</Text>
            <Text as='samp'>Os dados do cartão são seus, ou de outra pessoa?</Text>
            <RadioGroup onChange={setValue} value={value} mb={5}>
              <Stack direction='row'>
                <Radio value='1'>São Meus</Radio>
                <Radio value='2'>São de outra pessoa</Radio>
              </Stack>
            </RadioGroup>
            {
              value === '1' && (
                <>
                  <Input placeholder="Número do Cartão" mb={2} value={numeroCartao} onChange={handleChangeNumeroCartao} />
                  {errorNumeroCartao && (
                    <Alert status='warning'>
                      <AlertIcon />
                      <AlertTitle>Campo Obrigatório</AlertTitle>
                      <AlertDescription>É necessário preencher seu Número do cartão para continuar.</AlertDescription>
                    </Alert> 
                  )}
                  <Flex>
                    <Input placeholder="CVV" maxLength={3} width="30%" mb={2} value={cvvCartao} onChange={handleChangeCvvCartao} />
                    <Input placeholder="Validade MM/AAAA" width="30%" mb={2} ml={2} value={validadeCartao} onChange={handleChangeValidadeCartao} />
                  </Flex>
                  {errorCvvCartao && (
                    <Alert status='warning'>
                      <AlertIcon />
                      <AlertTitle>Campo Obrigatório</AlertTitle>
                      <AlertDescription>É necessário informar o código de segurança do seu cartão para continuar, o código de segurança são os 3 digitos normalmente localizados na parte de trás do seu cartão.</AlertDescription>
                    </Alert> 
                  )}
                  {errorValidadeCartao && (
                    <Alert status='warning'>
                      <AlertIcon />
                      <AlertTitle>Campo Obrigatório</AlertTitle>
                      <AlertDescription>Insirá a válidade do cartão de crédito para continuar.</AlertDescription>
                    </Alert>
                  )}
                  <Button colorScheme='orange' mt={5} mb={4} onClick={handleBack}>Voltar</Button>
                  <Button colorScheme='orange' mb={4} onClick={handleFinancialStage}>Continuar</Button>
                </>
              )
            }
            {
              value === '2' && (
                <>
                  <Input placeholder="Digite o nome do titular do cartão" mb={2} value={titularCartao} onChange={handleChangeTitularCartao} />
                  {errorTitularCartao && (
                    <Alert status='warning'>
                      <AlertIcon />
                      <AlertTitle>Campo Obrigatório</AlertTitle>
                      <AlertDescription>Por favor informe o nome do titular do cartão para continuar.</AlertDescription>
                    </Alert> 
                  )}
                  <Input placeholder="Digite o CPF do titular" mb={2} value={cpfTitular} onChange={handleChangeCpfTitular} />
                  {errorCpfTitular && (
                    <Alert status='warning'>
                      <AlertIcon />
                      <AlertTitle>Campo Obrigatório</AlertTitle>
                      <AlertDescription>Para continuar informe o cpf do titular do cartão.</AlertDescription>
                    </Alert> 
                  )}
                  <Input placeholder="Número do Cartão" mb={2} value={numeroCartao} onChange={handleChangeNumeroCartao} />
                  {errorNumeroCartao && (
                    <Alert status='warning'>
                      <AlertIcon />
                      <AlertTitle>Campo Obrigatório</AlertTitle>
                      <AlertDescription>É necessário preencher seu Número do cartão para continuar.</AlertDescription>
                    </Alert> 
                  )}
                  <Flex>
                    <Input placeholder="CVV" maxLength={3} width="30%" mb={2} value={cvvCartao} onChange={handleChangeCvvCartao} />
                    <Input placeholder="Validade MM/AAAA" width="30%" mb={2} ml={2} value={validadeCartao} onChange={handleChangeValidadeCartao} />
                  </Flex>
                  {errorCvvCartao && (
                    <Alert status='warning'>
                      <AlertIcon />
                      <AlertTitle>Campo Obrigatório</AlertTitle>
                      <AlertDescription>É necessário informar o código de segurança do seu cartão para continuar, o código de segurança são os 3 digitos normalmente localizados na parte de trás do seu cartão.</AlertDescription>
                    </Alert> 
                  )}
                  {errorValidadeCartao && (
                    <Alert status='warning'>
                      <AlertIcon />
                      <AlertTitle>Campo Obrigatório</AlertTitle>
                      <AlertDescription>Insirá a válidade do cartão de crédito para continuar.</AlertDescription>
                    </Alert>
                  )}
                  <Button colorScheme='orange' mt={5} mb={4} onClick={handleBack}>Voltar</Button>
                  <Button colorScheme='orange' mb={4} onClick={handleFinancialStageOtherTitular}>Continuar</Button>
                </>
              )
            }
          </Flex>
        )}

        {activeStep === 2 && (
          <Flex flexDirection="column" mb={4} mt={8} width={isLargerThan800 ? "70%" : "100%"}>
            <Text fontSize="xl" mb={4} color='tomato'>{steps[activeStep].description}</Text>
            <Flex
              flexDirection="column"
              padding="10px"
              alignItems="center"
              gap={2}
              border="1px solid #ff6100"
              borderRadius="8px"
            >
              <Text color="#ff6100" fontSize="27px">
                {planTitle}
              </Text>
              <Text color="#ff6100">Assinatura Mensal</Text>
              <Text fontSize="40px" fontWeight="700" color="#ff6100">
                {planValue}
              </Text>
              <Text color="#ff6100">
                Forma de Pagamento Cartão de Crédito
              </Text>
            </Flex>
            {
              iniciarPagamento && (
                <Flex mt={10} mb={10}>
                  <Spinner color='red.500'/>
                  <Text color="#ff6100" ml={3} width="30%">
                    Enviando
                  </Text>
                </Flex>
              )
            }
            {
              existeFalha && (
                <Alert status='warning'>
                  <AlertIcon />
                  <AlertTitle>{motivoFalha.title}</AlertTitle>
                  <AlertDescription>{motivoFalha.message}</AlertDescription>
                </Alert>
              )
            }
            {
              pagamentoConcluido && (
                <>
                  <Flex mt={10} mb={10}>
                    <Text color="#ff6100" ml={3} width="30%">
                      Pagamento Concluido
                    </Text>
                  </Flex>
                </>
              )
            }
            <Button colorScheme='orange' mt={5} mb={4} onClick={handleBack}>Voltar</Button>
            <Button colorScheme='orange' mb={4} onClick={confirmarAssinatura}>Confirmar</Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}
